<template>
	<main class="page-container container-bg container">
		<div class="news_main">
			<div v-if="data" class="mainNews">
				<AppText size="24" line-height="31" weight="700" class="mb-20">
					{{ data.mavzu | translate }}
				</AppText>
				<div class="news__img__main mb-20">
					<img :src="mainImage" alt="MainImage" />
				</div>
				<div class="news__img__list mb-20" v-if="isMultipleImages">
					<img
						@click="setImage(index)"
						v-for="(img, index) in data.photos.split(separator)"
						:class="{ news__img__list__active: index === activeImg }"
						:src="getImgUrl(img)"
						:alt="`${img}`"
					/>
				</div>
				<AppText size="14" line-height="18" class="mb-20 color-text-2" weight="500">
					{{ $t("publishedDate") }}: <span>{{ data.newsDate.slice(0, 10) }}</span>
				</AppText>
				<div
					class="block__content"
					:class="isMobileMedium ? 'mb-20' : 'mb-40'"
					v-html="data.text[`${$store.state.clng}`]"
				></div>
				<BlockWrap offset-y="20" class="mb-20">
					<AppText size="14" class="color-text-2"> {{ $t("shareOnSocialMediaNetworks") }} : </AppText>

					<div class="news__socials">
						<a
							:href="item.link"
							target="_blank"
							class="news__socials-item mr-20"
							v-for="(item, index) in socials"
							:key="index"
						>
							<img :src="item.icon" alt="" />
						</a>
					</div>
				</BlockWrap>
			</div>
			<div class="latestNews">
				<AppText :size="isMobileSmall ? '18' : '24'" line-height="31" weight="700" class="mb-20">
					{{ $t("latestNews") }}
				</AppText>
				<div>
					<template v-if="latestNews.length > 0">
						<template v-for="(item, idx) in latestNews">
							<template v-if="item.type === 2">
								<div :key="idx">
									<div class="latest-news__item mb-20">
										<div class="latest-news__photo latest-news__photo__notification">
											<img src="../../assets/icons/Notification.svg" alt="Notification" />
										</div>
										<div class="latest-news__content">
											<AppText size="14" line-height="18" weight="700" class="mb-10">
												<p v-html="item.mavzu[`${$store.state.clng}`]"></p>
											</AppText>

											<AppText size="12" line-height="16" weight="500" class="color-text-2">
												{{
													item.editDate === null
														? item.createDate.slice(0, 10)
														: item.editDate.slice(0, 10)
												}}
											</AppText>
										</div>
									</div>
								</div>
							</template>
							<template v-else>
								<div>
									<router-link :to="`/news/${item.id}`" class="latest-news__item mb-20">
										<div class="latest-news__photo">
											<img :src="getImgUrl(item.photos)" alt="ImageOfNews" />
										</div>
										<div class="latest-news__content">
											<AppText size="14" line-height="18" weight="700" class="mb-10">
												{{ item.mavzu ? item.mavzu[`${$store.state.clng}`] : "" }}
											</AppText>
											<AppText size="12" line-height="16" weight="500" class="color-text-2">
												{{ item.newsDate.slice(0, 10) }}
											</AppText>
										</div>
									</router-link>
								</div>
							</template>
						</template>
					</template>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import BlockWrap from "../../components/shared-components/BlockWrap";
import MainSwiper from "../../components/ui/MainSwiper";

export default {
	name: "SingleNews",
	components: {
		BlockWrap,
		AppText,
		MainSwiper,
	},
	data() {
		return {
			activeImg: 0,
			data: null,
			latestNews: [],
			query: {
				skip: 0,
				take: 5,
			},
			socials: [
				{
					icon: require("@/assets/icons/telegram.svg"),
					link: "https://t.me/uz_cooperation",
				},

				{
					icon: require("@/assets/icons/facebook.svg"),
					link: "",
				},

				{
					icon: require("@/assets/icons/instagram.svg"),
					link: "",
				},
			],
			adminUrl: process.env.VUE_APP_BASE_URL_DOMAIN,
		};
	},
	methods: {
		setImage(index) {
			this.activeImg = index;
		},
		load() {
			this.$api
				.get(`/api-client/Client/GetByNews`, {
					params: {
						id: this.$route.params.id,
					},
				})
				.then(
					(response) => {
						const res = response.data;
						if (res && res.result) {
							this.data = res.result.result.result;
						} else {
							this.$notification.error(res.error.message);
						}
					},
					(error) => {
						this.$notification.error(error);
					}
				)
				.finally(() => {
					this.loading = false;
				});
		},
		laodLatestNews() {
			this.$api
				.get(`/api-client/Client/GetAllNews`, {
					params: this.query,
				})
				.then(
					(response) => {
						const res = response.data;
						if (res && res.result) {
							this.latestNews = res.result.data;
						} else {
							this.$notification.error(res.error.message);
						}
					},
					(error) => {
						this.$notification.error(error);
					}
				)
				.finally(() => {
					this.loading = false;
				});
		},
		getImgUrl(imgUrl) {
			if (imgUrl != null) {
				let separator = imgUrl.includes(";") ? ";" : "|";
				let img = Array.isArray(imgUrl.split(separator)[0])
					? imgUrl.split(separator)[0][this.activeImg]
					: imgUrl.split(separator)[0];
				return this.adminUrl + img.replaceAll(separator, "").replaceAll("null", "");
			} else {
				return imgUrl;
			}
		},
	},
	created() {
		this.load();
		this.laodLatestNews();
	},
	computed: {
		separator() {
			return this.data.photos.includes(";") ? ";" : "|";
		},
		images() {
			let separator = this.data.photos.includes(";") ? ";" : "|";
			return this.data.photos.split(separator);
		},
		mainImage() {
			let separator = this.data.photos.includes(";") ? ";" : "|";
			return Boolean(this.data.photos.split(separator).length === 1 ? false : true)
				? this.adminUrl +
						this.data.photos
							.split(separator)
							[this.activeImg].replaceAll(separator, "")
							.replaceAll("null", "")
				: this.adminUrl + this.data.photos.split(separator)[0];
		},
		isMultipleImages() {
			let separator = this.data.photos.includes(";") ? ";" : "|";
			return Boolean(this.data.photos.split(separator).length === 1 ? false : true);
		},
	},

	watch: {
		"$route.params.id"(id) {
			this.load();
		},
	},
};
</script>

<style lang="scss">
.latest-news__item {
	display: grid;
	grid-template-columns: 120px 1fr;
	gap: 10px;
}
.latest-news__photo {
	img {
		width: 100%;
		height: 70px;
		object-fit: cover;
		border-radius: 5px;
	}
}
.latest-news__photo__notification {
	img {
		object-fit: contain;
	}
}
.mainNews,
.latestNews {
	width: 100%;
}
.news_main {
	display: grid;
	gap: 20px;
	grid-template-columns: 1fr 400px;
}

.news__img__main {
	img {
		object-fit: contain;
		object-position: center;
		border-radius: 10px;
		height: 600px;
		width: 100%;
	}
}
.news__img__list {
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(5, 1fr);
	img {
		cursor: pointer;
		border-radius: 5px;
		width: 100%;
		object-fit: fill;
	}
	.news__img__list__active {
		box-shadow: 0 5px 20px #07165c;
	}
}
@media (max-width: 768px) {
	.latest-news__item {
		grid-template-columns: 200px 1fr;
		img {
			height: 130px;
		}
	}
	.news_main {
		grid-template-columns: 1fr !important;
		gap: 10px;
	}
	.news__img__main {
		width: 100%;
		img {
			height: 250px;
			width: 100%;
		}
	}
}
</style>

